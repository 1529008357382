.root {
  position: relative;
  overflow: hidden;
  background-color: var(--bgColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 12px;
  border-top: 2px solid var(--textColor);
  border-bottom: 2px solid var(--textColor);
}

.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.detailsWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.heading {
  font-size: 16px;
  font-weight: 600;
  color: var(--textColor);
}

.cta {
  padding: 10px 28px;
  text-align: center;
  width: fit-content;
  border-radius: var(--br-xxs);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-sm);
  border: none;
  cursor: pointer;
  color: var(--black);
  transition: transform 0.5s ease;
  background-color: var(--yellow);
}

.cta :hover {
  cursor: pointer;
  transform: scale(1.04);
  box-shadow: 0 14px 40px rgba(30, 10, 58, 0.1);
}

.image {
  object-fit: cover;
  border-radius: 50%;
  height: 92px;
  width: 92px;
}

.subheading {
  color: var(--black);
  font-weight: 500;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 767px) {
  .plugImage {
    display: none;
  }
  .detailsWrap {
    gap: 10px;
  }
}
