.root {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s linear;
  position: relative;
}

.pointer {
  cursor: pointer;
}

.title {
  font-size: var(--font-md);
  font-weight: var(--font-weight-midBold);
}

.cardImg, .mobileCardImage {
  object-fit: cover;
  object-position: top;
  flex: none;
}

.cardDetailsWrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  border-radius: var(--br-xxs);
  overflow: hidden;
  transition: all 0.3s ease;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  gap: 8px;
}

@media (max-width: 500px) {
  .mobileCardImage {
    width: 100%;
    height: 120px;
  }

  .cardImg {
    display: none;
  }

  .content {
    flex-direction: column;
  }

  .cardDetailsWrapper {
    flex-direction: column;
  }
}

@media (min-width: 500px) {
  .title {
    font-size: var(--font-md);
  }

  .mobileCardImage {
    display: none;
  }

  .cardDetailsWrapper:hover {
    box-shadow: var(--section-card-box-shadow);
  }
}

.ctaButton {
  height: fit-content;
  background-color: var(--yellow);
  padding: 10px 28px;
  border-radius: var(--br-xxs);
  font-weight: var(--font-weight-medium);
  text-align: center;
  cursor: pointer;
  flex: none;
  border: none;
  width: fit-content;
  margin: auto 0;
}

.description {
  font-size: var(--font-sm);
  color: var(--black);
  margin: 4px 0 0 0;
}

.trunc-1,
.trunc-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.trunc-1 {
  -webkit-line-clamp: 1;
}

.trunc-2 {
  -webkit-line-clamp: 2;
}
