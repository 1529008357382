.listItem {
  box-sizing: border-box !important;
  width: 100%;
}

.flexgrid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

@media (max-width: 480px) {
  .flexgrid > * {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .flexgrid > * {
    flex: 1 1 100% !important;
    max-width: 100% !important;
  }
}
