.title {
  font-size: var(--font-4xl);
  font-weight: var(--font-weight-midBold);
}

.media {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.media.center {
  justify-content: center;
}

.media.left {
  justify-content: flex-start;
}

.media.right {
  justify-content: flex-end;
}

.media > * {
  position: relative;
  border: none;
  outline: none;
}

.image {
  overflow: auto;
  object-fit: contain;
  height: auto;
}

.cta {
  padding: 10px 18px;
  text-align: center;
  width: fit-content;
  border-radius: var(--br-xxs);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-sm);
  border: none;
  cursor: pointer;
  color: var(--black);
  transition: all 0.5s ease;
  background-color: var(--yellow);

  display: flex;
  align-items: center;
  gap: 4px;
}

.cta:hover {
  box-shadow: var(--section-card-box-shadow);
}

@media only screen and (max-width: 600px) {
  .image {
    width: 100%;
  }
}
