.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.heading {
  text-align: center !important;
  margin-inline: auto;
  font-weight: var(--font-weight-midBold);
  font-size: var(--font-4xl);
}

.subHeading,
.description {
  font-size: var(--font-sm);
  line-height: 1.5rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cta {
  padding: 10px 28px;
  text-align: center;
  width: fit-content;
  border-radius: var(--br-xxs);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-sm);
  border: none;
  cursor: pointer;
  color: var(--black);
  transition: all 0.5s ease;
  background-color: var(--yellow);

  display: flex;
  align-items: center;
  gap: 4px;
}

.cta:hover {
  box-shadow: var(--section-card-box-shadow);
}
