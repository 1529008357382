.root {
  padding: 12px !important;
  position: relative !important;
  overflow: hidden !important;
  background-color: var(--bgColor) !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  gap: 8px !important;
  cursor: pointer !important;
  border-top: 2px solid var(--textColor) !important;
  border-bottom: 2px solid var(--textColor) !important;
}

.root.verticalLayout {
  border: none !important;
}

.header {
  display: flex !important;
  flex-direction: column !important;
  gap: 0px !important;
}

.verticalLayout .body {
  justify-content: center !important;
}

.body {
  display: flex !important;
  justify-content: space-between !important;
  gap: 8px !important;
  align-items: center !important;
}

.detailsWrap {
  display: flex !important;
  flex-direction: column !important;
  gap: 8px !important;
  width: 100% !important;
}

.trunc-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.heading {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: var(--textColor) !important;
  margin-bottom: 0 !important;
}

.title {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.cta {
  padding: 10px 18px !important;
  text-align: center !important;
  flex: none !important;
  height: fit-content !important;
  border-radius: var(--br-xxs) !important;
  font-weight: var(--font-weight-medium) !important;
  font-size: var(--font-sm) !important;
  border: none !important;
  cursor: pointer !important;
  color: var(--black) !important;
  transition: transform 0.5s ease !important;
  background-color: var(--yellow) !important;
}

.tags {
  display: flex !important;
  gap: 4px !important;
  align-items: center !important;
  margin-top: 4px !important;
  flex-wrap: wrap !important;
}

.tag {
  width: fit-content !important;
  align-items: center !important;
  gap: 6px !important;
  color: var(--textColor) !important;
  background-color: var(--white) !important;
  font-size: var(--font-xs) !important;
  padding: 4px 10px !important;
  border-radius: var(--br-xxs) !important;
  font-weight: var(--font-weight-medium) !important;
}

.cta:hover {
  cursor: pointer !important;
  transform: scale(1.04) !important;
  box-shadow: 0 14px 40px rgba(30, 10, 58, 0.1) !important;
}

.image {
  object-fit: cover !important;
  border-radius: var(--br-xxs) !important;
  height: 96px !important;
  width: 100% !important;
}

.uspText {
  position: absolute !important;
  z-index: 2 !important;
  top: 0 !important;
  right: 0 !important;
  background-color: var(--white) !important;
  width: fit-content !important;
  color: var(--textColor) !important;
  padding: 4px 10px !important;
  border-bottom-left-radius: var(--br-xxs) !important;
  border-top-right-radius: var(--br-xxs) !important;
  font-size: var(--font-xs) !important;
  font-weight: var(--font-weight-medium) !important;
}

.subHeading {
  color: var(--black) !important;
  line-height: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.verticalLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: centers;
}

.verticalLayout .cta {
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 12px;
}

@media screen and (max-width: 767px) {
  .plugImage {
    display: none !important;
  }
}
